/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SubFooter from "../components/SubFooter"
import Sidebar from "../components/Sidebar/Sidebar"
import TopBar from "../components/TopBar"
import { fonts, mediaQueries } from "../styles"

export default ({ data }) => {
  const imageSet = data.allFile.edges.reduce((result, current) => {
    result[current.node.base.split(".")[0].slice(-1)] =
      current.node.childImageSharp.fluid
    return result
  }, {})

  return (
    <Layout title="STUDIO">
      <Sidebar title="STUDIO" />
      {/* Main Content */}
      <div
        css={css`
          margin-left: 40vw;
          min-height: 100vh;
          display: grid;
          grid-template-rows: auto 1fr auto;
          ${mediaQueries.phoneLarge} {
            display: block;
            margin-left: 0;
          }
        `}
      >
        <TopBar />
        <div>
          <div
            css={css`
              margin: 60px;
              font-family: ${fonts.sans};
              font-size: 20px;
              font-weight: 600;
              line-height: 33px;
              ${mediaQueries.phoneLarge} {
                font-size: 14px;
                line-height: 26px;
              }
            `}
          >
            破除传统甲方单方面委托式的设计模式，通过于用户的深度交流剖析设计需求。日式设计的优势不仅更加贴合人性需求，同时也兼并商业性走在当代审美的最前沿。清水设计工作室主营范围横跨服装，产品，平面，交互，新媒体，策展，空间，摄影，建筑等领域是多维一体式的综合工作室。在设计项目中视觉仅仅是一个局部，立案，策划，核心概念的产出，来自清水设计工作室的日系团队带给你全新的设计体验。
          </div>
          <hr
            css={css`
              margin: 100px 0;
              background-color: black;
            `}
          />
          {/* Section 1 */}
          <div>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 20px 0;
              `}
            >
              <Img fluid={imageSet[1]} />
              <Img fluid={imageSet[2]} />
            </div>
            <Img
              fluid={imageSet[3]}
              css={css`
                margin: 20px 40px;
              `}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-around;
                height: 200px;
                padding-top: 20px;
                background-color: rgb(244, 244, 244);
              `}
            >
              <h4
                css={css`
                  font-family: ${fonts.sans};
                  &:before {
                    content: "";
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    position: relative;
                    top: 1px;
                    margin-right: 10px;
                    background-color: #29292a;
                    display: inline-block;
                  }
                `}
              >
                北京服装学院导视系统
              </h4>
              <ul
                css={css`
                  list-style: none;
                `}
              >
                <li>T: 导视设计</li>
                <li>CL: 北京服装学院</li>
                <li>AD: KM Atelier</li>
                <li>D: KM Atelier</li>
              </ul>
            </div>
          </div>
          {/* Section 2 */}
          <div
            css={css`
              background-color: #030b5d;
              padding-top: 60px;
            `}
          >
            <Img fluid={imageSet[4]} />
            <div
              css={css`
                display: flex;
                justify-content: space-around;
                height: 200px;
                padding-top: 20px;
                color: white;
              `}
            >
              <h4
                css={css`
                  font-family: ${fonts.sans};
                  &:before {
                    content: "";
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    position: relative;
                    top: 1px;
                    margin-right: 10px;
                    display: inline-block;
                    background-color: white;
                  }
                `}
              >
                中国电影协会奖
              </h4>
              <ul
                css={css`
                  list-style: none;
                `}
              >
                <li>T: 颁奖典礼视觉设计</li>
                <li>CL: 李少红</li>
                <li>AD: KM Atelier</li>
                <li>D: KM Atelier</li>
              </ul>
            </div>
          </div>
          {/* Section 3 */}
          <div
            css={css`
              background-color: rgb(244, 244, 244);
            `}
          >
            <Img
              fluid={imageSet[5]}
              css={css`
                margin: 5px 20px;
              `}
            />
            <Img
              fluid={imageSet[6]}
              css={css`
                margin: 5px 60px;
              `}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-around;
                height: 200px;
                padding-top: 20px;
                background-color: rgb(244, 244, 244);
              `}
            >
              <h4
                css={css`
                  font-family: ${fonts.sans};
                  &:before {
                    content: "";
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    position: relative;
                    top: 1px;
                    margin-right: 10px;
                    background-color: #29292a;
                    display: inline-block;
                  }
                `}
              >
                西海48
              </h4>
              <ul
                css={css`
                  list-style: none;
                `}
              >
                <li>T: 景观视觉系统</li>
                <li>CL: 西海48</li>
                <li>AD: KM Atelier</li>
                <li>D: KM Atelier</li>
              </ul>
            </div>
          </div>
          {/* Section 4 */}
          <div
            css={css`
              height: 200px;
              background-color: rgb(35, 35, 35);
            `}
          ></div>
        </div>
        <SubFooter />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "design" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
